import { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { Button, FormControlLabel, Switch } from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'

import { praxis } from '../../../config/themeConfig'

import {
  getEditableMetadata,
  getUpdateMetadataPayload,
} from '../../mappers/metadataMapper'
import {
  fetchCatalogForAsset,
  updateMetadataForAsset,
} from '../../store/metadata/metadataSlice'

import AssetDetailsCard from './AssetDetailsCard'
import MetadataContext from './MetadataContext'
import UsageInfoCard from './UsageInfoCard'
import LibrarianStatusCard from './LibrarianStatusCard'
import RevisionsCard from './RevisionsCard'
import ProjectInfoCard from './ProjectInfoCard'
import MetadataTags from './MetadataTags'
import AssetClassificationCard from './AssetClassificationCard'
import AssetDescriptionsCard from './AssetDescriptionsCard'
import ProductInfoCard from './ProductInfoCard'
import {
  BetaAnalytics,
  trackUpdateMetadata,
} from '../../../analytics/betaSearch'
import AIMetadataCard from './AIMetadataCard'

const styles = makeStyles((theme) => ({
  chipContainer: {
    display: 'grid',
  },
  chipComponent: {
    marginBottom: '8px',
    justifyContent: 'space-between',
    backgroundColor: praxis.lightestGrey,
  },
  errorStyle: {
    color: praxis.bostonRed + ' !important',
  },
  personShownFormControl: {
    minWidth: '50ch',
    margin: '10px'
  },
  formControl: {
    minWidth: '100px',
    margin: useTheme().spacing(1),
    '& label': {
      color: praxis.lightGrey + ' !important',
    },
    '& input, textarea': {
      color: praxis.midGrey,
    },
  },
  formStaticWrap: {
    marginTop: '18px',
  },
  fullWidth: {
    width: '90%',
    display: 'block',
  },
  noDetailsAvailable: {
    padding: '20px 15px 0px',
    color: '#6f6a6a',
    fontSize: '16px',
  },
  progressSpinner: {
    width: '20px !important',
    height: '20px !important',
  },
  cardTitle: {
    fontSize: '1.5em',
    display: 'inline',
  },
  metadataWrap: {
    overflowY: 'auto',
    padding: '5px',
  },
  metadataCard: {
    paddingTop: '20px',
  },
  statusCard: {
    padding: '10px 0px',
  },
  metadataCardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '20px',
  },
  metadataCardActionWrapper: {
    marginLeft: '20px',
  },
  metadataDivider: {
    width: '100%',
    margin: '10px 0',
  },
  metadataSubHeading: {
    fontSize: useTheme().typography.pxToRem(18),
    fontWeight: useTheme().typography.fontWeightRegular,
  },
  metadataTable: {
    margin: '0',
    padding: '0 8px',
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  metadataTableRow: {
    '&:hover': {
      backgroundColor: praxis.lightestGrey,
      '& .actionBtnHover': {
        visibility: 'visible',
      },
    },
  },
  metadataTableCell: {
    flex: 1,
    padding: '0 !important',
    '& label, input': {
      fontSize: '0.8rem !important',
    },
  },
  metadataTableCellAction: {
    width: '20px',
    marginLeft: '10px',
    '& .error': {
      color: praxis.bostonRed + ' !important',
    },
  },
  metadataTableCellActionBtn: {
    visibility: 'hidden',
  },
  noResults: {
    width: '50%',
    textAlign: 'center',
    color: praxis.midGrey,
    marginTop: '2%',
    fontSize: '20px',
    margin: '0 auto',
    paddingBottom: '3%',
  },
  switchChecked: {
    color: useTheme().palette.primary.dodgerBlue + ' !important',
    '& + $colorBar': {
      backgroundColor: useTheme().palette.primary.dodgerBlue + ' !important',
    },
  },
  colorBar: {},
  actionButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionMessage: {
    color: useTheme().palette.primary.primaryGreen,
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: 'small',
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonStyle: {
    color: useTheme().palette.primary.primaryBlue,
    padding: '10px',
    marginLeft: '10px',
  },
  actionButtonPrimary: {
    backgroundColor: useTheme().palette.primary.primaryBlue,
    color: useTheme().palette.primary.contrastText,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.primaryDarkBlue,
    },
  },
  usageDetails: {
    font: '12px',
    padding: '0px 40px 20px 40px',
  },
  usageDetailsLabel: {
    color: '#555555',
    border: 'none',
  },
  usageSummaryLabel: {
    width: 'max-content !important',
  },
  usageDetailsText: {
    color: '#212121',
    fontSize: '13px',
  },
  noBorderClass: {
    border: 'none',
  },
  usageRightsAccordion: {
    backgroundColor: '#FAFAFA',
    margin: '10px 20px !important',
  },
  assetGrid: {
    padding: '20px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  autocompleteRoot: {
    minWidth: '200px',
  },
  selectWidth: {
    minWidth: '150px',
  },
  aiMetadataGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },
  aiMetadataChipClass: {
    margin: '5px'
  }
}))

function MetadataInfoPage({ assetMetadata = {} }) {
  const classes = styles()
  const dispatch = useDispatch()
  const {
    ENABLE_AI_ENRICHMENT = false,
  } = useEnv()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isMetadataUnsaved, setIsMetadataUnsaved] = useState(false)
  const defaultEditableMetadata = getEditableMetadata(assetMetadata)
  const [editedMetadata, setEditedMetadata] = useState(defaultEditableMetadata)
  const authData = useSelector((state) => state.auth)
  const { isAdGroupSuperAdmin = false, isLibrarian = false } = authData
  const canEditMetadata = isAdGroupSuperAdmin || isLibrarian

  const { asset_id = '' } = assetMetadata

  const saveMetadata = () => {
    const editMetadataPayload = getUpdateMetadataPayload(
      asset_id,
      editedMetadata,
    )
    dispatch(updateMetadataForAsset(editMetadataPayload))
    trackUpdateMetadata({
      authData,
      metadataPayload: editMetadataPayload?.[0],
      trackingData: {
        eventType: BetaAnalytics.event.ASSET_USAGE,
        eventName: BetaAnalytics.event.SINGLE_UPDATE_METADATA,
      },
    })
  }

  const updateMetadata = (fieldName, fieldValue) => {
    setEditedMetadata({ ...editedMetadata, [fieldName]: fieldValue })
  }

  const updateMetadataInBulk = (bulkPayload = {}) => {
    setEditedMetadata({ ...editedMetadata, ...bulkPayload })
  }

  useEffect(() => {
    dispatch(fetchCatalogForAsset())
  }, [])
  return (
    <MetadataContext.Provider
      value={{
        assetMetadata: assetMetadata,
        isMetadataUnsaved: isMetadataUnsaved,
        isEditMode: isEditMode,
        editedMetadata: editedMetadata,
        updateMetadata,
        updateMetadataInBulk,
        setIsMetadataUnsaved,
      }}
    >
      <div className={classes.metadataWrap}>
        <div className={classes.actionButtonsContainer}>
          {canEditMetadata && (
            <FormControlLabel
              id={`${isEditMode}`}
              control={
                <Switch
                  data-cy="editModeSwitch"
                  checked={isEditMode}
                  onChange={() => setIsEditMode(!isEditMode)}
                  value={`${isEditMode}`}
                  classes={{
                    checked: classes.switchChecked,
                    track: classes.colorBar,
                  }}
                />
              }
              label="Edit Mode"
            />
          )}
          <div className={classes.actionButtonWrap}>
            {/* {isSaved && (
            <span className={classes.actionMessage}>
              <CheckIcon />
              <span>Metadata added</span>
            </span>
          )} */}
            {isEditMode && (
              <Button
                data-cy="saveMetadata"
                className={cx(
                  classes.actionButtonStyle,
                  classes.actionButtonPrimary,
                )}
                variant="contained"
                id="bulkEditSaveButton"
                onClick={saveMetadata}
                disabled={!isMetadataUnsaved}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
        <div className={classes.metadataCard}>
          <AssetDetailsCard containerClasses={classes} />
        </div>
        <div className={classes.metadataCard}>
          <UsageInfoCard containerClasses={classes} />
        </div>
        <div className={classes.metadataCard}>
          <ProductInfoCard containerClasses={classes} />
        </div>
        <div className={classes.metadataCard}>
          <AssetDescriptionsCard containerClasses={classes} />
        </div>
        <div className={classes.metadataCard}>
          <AssetClassificationCard containerClasses={classes} />
        </div>
        {
          ENABLE_AI_ENRICHMENT && (
            <div className={classes.metadataCard}>
              <AIMetadataCard containerClasses={classes} />
            </div>
          )
        }
        {canEditMetadata && (
          <div className={classes.metadataCard}>
            <MetadataTags containerClasses={classes} />
          </div>
        )}
        <div className={classes.statusCard}>
          <ProjectInfoCard containerClasses={classes} />
        </div>
        <div className={classes.statusCard}>
          <RevisionsCard containerClasses={classes} />
        </div>
        {canEditMetadata && (
          <div className={classes.statusCard}>
            <LibrarianStatusCard containerClasses={classes} />
          </div>
        )}
      </div>
    </MetadataContext.Provider>
  )
}

export default MetadataInfoPage

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { isEqual } from 'lodash'

import { FormControlLabel, Switch, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Check as CheckIcon } from '@mui/icons-material'

import { AssetDetailsCard } from './AssetDetailsCard'
import { UsageInfoCard } from './UsageInfoCard'
import LibrarianInfoCard from './LibrarianInfoCard'
import RevisionsCard from './RevisionsCard'
import LibrarianStatusCard from './LibrarianStatusCard'

import {
  getMetadataContract,
  getSaveMetadataContract,
  getStorageIdentifier,
  collapseCallback,
  getNonProductBrand,
} from '../../helpers/MetadataHelper'
import {
  fetchAssetMetadata,
  resetAssetMetadata,
  updateMetadata,
  fetchCatalogData,
  isMetadataFormEdited,
} from '../../store/metadata/actionCreator'
import { selectIsMetadataFormEdited } from '../../store/metadata/selector'
import { selectSaveAlertActiontype } from '../../store/search/selector'
import { makeSelectIsLoggedInUserProjectAdmin } from '../../store/projects/selector'
import {
  selectIsAdGroupSuperAdmin,
  selectIsLibrarian,
  selectUserId,
} from '../../store/auth/selector'
import { METADATA_CATALOG_TYPES } from '../../constants/metadata'
import { praxis } from '../../config/themeConfig'

const styles = makeStyles((theme) => ({
  chipContainer: {
    display: 'grid',
  },
  chipComponent: {
    marginBottom: '8px',
    justifyContent: 'space-between',
    backgroundColor: praxis.lightestGrey,
  },
  errorStyle: {
    color: praxis.bostonRed + ' !important',
  },
  personShownFormControl: {
    minWidth: '50ch',
    margin: '10px'
  },
  formControl: {
    minWidth: '100px',
    margin: useTheme().spacing(1),
    '& label': {
      color: praxis.lightGrey + ' !important',
    },
    '& input, textarea': {
      color: praxis.midGrey,
    },
  },
  formStaticWrap: {
    marginTop: '18px',
  },
  fullWidth: {
    width: '90%',
    display: 'block',
  },
  progressSpinner: {
    width: '20px !important',
    height: '20px !important',
  },
  metadataWrap: {
    overflowY: 'auto',
    padding: '5px',
  },
  metadataCard: {
    paddingTop: '20px',
  },
  revisionCard: {
    padding: '20px 0px',
  },
  metadataCardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '20px',
  },
  metadataCardActionWrapper: {
    marginLeft: '20px',
  },
  metadataDivider: {
    width: '100%',
    margin: '10px 0',
  },
  metadataSubHeading: {
    fontSize: useTheme().typography.pxToRem(18),
    fontWeight: useTheme().typography.fontWeightRegular,
  },
  metadataTable: {
    margin: '0',
    padding: '0 8px',
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  metadataTableRow: {
    '&:hover': {
      backgroundColor: praxis.lightestGrey,
      '& .actionBtnHover': {
        visibility: 'visible',
      },
    },
  },
  metadataTableCell: {
    flex: 1,
    padding: '0 !important',
    '& label, input': {
      fontSize: '0.8rem !important',
    },
  },
  metadataTableCellAction: {
    width: '20px',
    marginLeft: '10px',
    '& .error': {
      color: praxis.bostonRed + ' !important',
    },
  },
  metadataTableCellActionBtn: {
    visibility: 'hidden',
  },
  noResults: {
    width: '50%',
    textAlign: 'center',
    color: praxis.midGrey,
    marginTop: '2%',
    fontSize: '20px',
    margin: '0 auto',
    paddingBottom: '3%',
  },
  switchChecked: {
    color: useTheme().palette.primary.dodgerBlue + ' !important',
    '& + $colorBar': {
      backgroundColor: useTheme().palette.primary.dodgerBlue + ' !important',
    },
  },
  colorBar: {},
  actionButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionMessage: {
    color: useTheme().palette.primary.primaryGreen,
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: 'small',
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonStyle: {
    color: useTheme().palette.primary.primaryBlue,
    padding: '10px',
    marginLeft: '10px',
  },
  actionButtonPrimary: {
    backgroundColor: useTheme().palette.primary.primaryBlue,
    color: useTheme().palette.primary.contrastText,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.primaryDarkBlue,
    },
  },
  usageDetails: {
    font: '12px',
    padding: '0px 40px 20px 40px',
  },
  usageDetailsLabel: {
    color: '#555555',
    border: 'none',
  },
  usageSummaryLabel: {
    width: 'max-content !important',
  },
  usageDetailsText: {
    color: '#212121',
    fontSize: '13px',
  },
  noBorderClass: {
    border: 'none',
  },
  usageRightsAccordion: {
    backgroundColor: '#FAFAFA',
    margin: '10px 20px !important',
  },
}))

export class MetadataBar extends React.Component {
  constructor(props) {
    super(props)
    const {
      assetData = {},
      assetId = '',
      storedMetadata = {},
      isError,
      fetchAssetMetadata = () => {},
      resetAssetMetadata = () => {},
    } = props

    this.state = {
      ...getMetadataContract(assetData),
      metadataParentTimestamp: Date.now(),
      isSaved: false,
      editMode: false,
    }

    if (Object.keys(assetData).length === 0 && assetId !== '') {
      // if Metadata passed by Consumer is empty
      if (storedMetadata.asset_id === assetId) {
        // if Metadata from Store is available for the assetId, then display from that
        this.state = {
          ...getMetadataContract(storedMetadata),
          metadataParentTimestamp: Date.now(),
          editMode: false,
        }
      }
      if (assetId && assetId != null) {
        fetchAssetMetadata(assetId)
      } else if (!isError) {
        resetAssetMetadata()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      assetData = {},
      assetId = '',
      storedMetadata = {},
      isError,
      fetchAssetMetadata = () => {},
      resetAssetMetadata = () => {},
      fetchCatalogData = () => {},
      saveAlertActiontype = '',
      setSaveAlertAction = () => {},
      isMetadataLoading,
    } = this.props
    const { editMode } = this.state
    const isPropsMetadataEmpty = Object.keys(assetData).length === 0
    const isStoreMetadataEmpty = Object.keys(storedMetadata).length === 0
    const didPropsMetadataChange = !isEqual(prevProps.assetData, assetData)
    const didStoreMetadataChange = !isEqual(
      prevProps.storedMetadata,
      storedMetadata
    )
    const isStoreMetadataMatchingAssetId = storedMetadata.asset_id === assetId

    // if Metadata is passed via props from Consumer
    if (didPropsMetadataChange) {
      this.setState({
        ...getMetadataContract(assetData),
        metadataParentTimestamp: Date.now(),
      })
    }

    if (editMode && prevState.editMode !== editMode) {
      // Trigger only when Edit mode is toggled
      fetchCatalogData(METADATA_CATALOG_TYPES)
    }
    if (
      !isEqual(prevProps.saveAlertActiontype, saveAlertActiontype) &&
      saveAlertActiontype === 'save_single_edit'
    ) {
      this.saveMetadata()
      setSaveAlertAction('')
    }
  }

  checkEditPermissions = () => {
    // Need to add RBA related checks here
    // Check if Asset Metadata is editable
    const { editMode = false } = this.state || {}
    const { isProjectAdmin = false } = this.props
    let canRename
    let canEdit
    let canDelete
    canRename = canEdit = editMode
    // check if user is project admin & navigated from project dash board then providing delete revision.
    canDelete = editMode || isProjectAdmin
    return {
      canRename,
      canEdit,
      canDelete,
    }
  }

  updateContainerState = (updatedMetadata = {}) => {
    this.setState((state) => ({
      ...Object.assign({}, state, updatedMetadata),
      unsavedChanges: true,
    }))
    this.props.isMetadataFormEdited(true)
  }

  saveMetadata = (updatedMetadata = {}) => {

    const { lanId, consumer } = this.props
    const updatedState = Object.assign({}, this.state, updatedMetadata)
    const payload = getSaveMetadataContract(updatedState)

    // call the Metadata Update API
    updateMetadata(
      payload,
      this.saveMetadataCallback,
      updatedState,
      lanId,
      consumer
    )
  }

  saveMetadataCallback = (updatedState, response, responseType) => {
    const {
      saveAlertActiontype = '',
      setSaveAlertAction = () => {},
      isMetadataFormEdited = () => {},
      closePreview = () => {},
    } = this.props
    if (responseType === 'success') {
      this.setState({
        ...updatedState,
        isSaved: true,
        metadataParentTimestamp: Date.now(),
      })
      isMetadataFormEdited(false)
      if (saveAlertActiontype === 'save_single_edit') {
        setSaveAlertAction('')
        setTimeout(() => {
          closePreview()
        }, 700)
      }
    }
  }

  toggleEditMode = () => {
    const { editMode } = this.state
    this.setState({
      editMode: !editMode,
    })
  }

  onLibrarianReviewStatusChange = (event = {}) => {
    const { isMetadataFormEdited = () => {} } = this.props
    this.setState({
      librarianReviewStatus: event?.target?.value,
    })
    isMetadataFormEdited(true)
  }

  render() {
    const {
      classes = {},
      assetId = '',
      isMetadataEditEnabled,
      storedCatalog = {},
      closePreview = () => {},
      isMetadataFormEdited,
      saveAlertActiontype,
      setSaveAlertAction = () => {},
      unsavedChanges,
      storedMetadata = {},
      isAdminOrLibrarian = false,
    } = this.props
    const {
      created_by = 'Not Provided',
      create_date = '',
      metadata: { file_metadata = {} } = {},
    } = storedMetadata
    const { person_shown = [] } = file_metadata
    const {
      assetMasterId = '',
      assetDetails = {},
      usageInformation = [],
      usageInstructions = {},
      metadataParentTimestamp = '',
      projectDetails = {},
      productInformation = [],
      storeInformation = [],
      assetDescriptions = {},
      assetClassifications = {},
      editMode = false,
      isSaved,
      librarianReviewStatus = '',
      userTags = {},
      ai = {},
    } = this.state
    const nonProductInfo = getNonProductBrand(productInformation || [])
    const { brandName = null } = nonProductInfo || {}
    const libMetadata = {
      projectDetails,
      productInformation,
      storeInformation,
      assetDescriptions,
      assetClassifications,
      userTags,
      ai
    }
    if (brandName !== null) {
      libMetadata.userDefinedBrandName = brandName
    }
    const editPermissions = this.checkEditPermissions()

    return (
      <div className={classes.metadataWrap}>
        <div className={classes.actionButtonsContainer}>
          {isMetadataEditEnabled && (
            <FormControlLabel
              id={`${editMode}`}
              control={
                <Switch
                  data-cy="editModeSwitch"
                  checked={editMode}
                  onChange={this.toggleEditMode}
                  value={`${editMode}`}
                  classes={{
                    checked: classes.switchChecked,
                    track: classes.colorBar,
                  }}
                />
              }
              label="Edit Mode"
            />
          )}
          <div className={classes.actionButtonWrap}>
            {isSaved && (
              <span className={classes.actionMessage}>
                <CheckIcon />
                <span>Metadata Added</span>
              </span>
            )}
            {editMode && (
              <Button
                data-cy="saveMetadata"
                className={cx(
                  classes.actionButtonStyle,
                  classes.actionButtonPrimary
                )}
                variant="contained"
                id="bulkEditSaveButton"
                onClick={this.saveMetadata}
                disabled={!unsavedChanges}
              >
                Save Changes
              </Button>
            )}
          </div>
        </div>
        <div className={classes.metadataCard}>
          <AssetDetailsCard
            classes={classes}
            assetDetails={{
              ...assetDetails,
              create_date,
              created_by,
              person_shown,
            }}
            metadataParentTimestamp={metadataParentTimestamp}
            collapseCallback={collapseCallback}
            getStorageIdentifier={getStorageIdentifier}
            editPermissions={editPermissions}
            saveMetadata={this.saveMetadata}
            isMetadataFormEdited={isMetadataFormEdited}
            saveAlertActiontype={saveAlertActiontype}
            setSaveAlertAction={setSaveAlertAction}
            updateParentState={this.updateContainerState}
          />
        </div>
        <div className={classes.metadataCard}>
          <UsageInfoCard
            classes={classes}
            usageInformation={usageInformation}
            usageInstructions={usageInstructions}
            metadataParentTimestamp={metadataParentTimestamp}
            collapseCallback={collapseCallback}
            getStorageIdentifier={getStorageIdentifier}
            editPermissions={editPermissions}
            saveMetadata={this.saveMetadata}
            isMetadataFormEdited={isMetadataFormEdited}
            saveAlertActiontype={saveAlertActiontype}
            setSaveAlertAction={setSaveAlertAction}
            updateParentState={this.updateContainerState}
          />
        </div>
        <div className={classes.metadataCard}>
          <LibrarianInfoCard
            containerClasses={classes}
            projectDetails={projectDetails}
            libMetadata={libMetadata}
            storedCatalog={storedCatalog}
            metadataParentTimestamp={metadataParentTimestamp}
            collapseCallback={collapseCallback}
            getStorageIdentifier={getStorageIdentifier}
            editPermissions={editPermissions}
            saveMetadata={this.saveMetadata}
            isMetadataFormEdited={isMetadataFormEdited}
            saveAlertActiontype={saveAlertActiontype}
            setSaveAlertAction={setSaveAlertAction}
            isAdminOrLibrarian={isAdminOrLibrarian}
            updateParentState={this.updateContainerState}
            editMode={editMode}
          />
        </div>
        <div className={classes.revisionCard}>
          <RevisionsCard
            containerClasses={classes}
            assetId={assetId}
            assetMasterId={assetMasterId}
            metadataParentTimestamp={metadataParentTimestamp}
            collapseCallback={collapseCallback}
            getStorageIdentifier={getStorageIdentifier}
            editPermissions={editPermissions}
            closePreview={closePreview}
          />
        </div>
        {isAdminOrLibrarian && (
          <div className={classes.revisionCard}>
            <LibrarianStatusCard
              editMode={editMode}
              classes={classes}
              librarianReviewStatus={librarianReviewStatus}
              onLibrarianReviewStatusChange={this.onLibrarianReviewStatusChange}
            />
          </div>
        )}
      </div>
    )
  }
}

MetadataBar.propTypes = {
  classes: PropTypes.object,
  consumer: PropTypes.string,
  assetData: PropTypes.object,
  assetId: PropTypes.string,
  storedMetadata: PropTypes.object,
  storedCatalog: PropTypes.object,
  isError: PropTypes.bool,
  isMetadataEditEnabled: PropTypes.bool,
  closePreview: PropTypes.func,
  fetchAssetMetadata: PropTypes.func,
  resetAssetMetadata: PropTypes.func,
  fetchCatalogData: PropTypes.func,
  isMetadataFormEdited: PropTypes.func,
  saveAlertActiontype: PropTypes.bool,
  setSaveAlertAction: PropTypes.func,
  unsavedChanges: PropTypes.bool,
  updateMetadata: PropTypes.func,
  fetchAsset: PropTypes.func,
  isProjectAdmin: PropTypes.bool,
  lanId: PropTypes.string,
}

const mapStateToProps = (state = {}) => {
  const { metadata = {} } = state
  const { assetMetadata = {}, assetCatalog = {}, isError = false } = metadata
  return {
    storedMetadata: assetMetadata,
    storedCatalog: assetCatalog,
    isError,
    isMetadataEditEnabled:
      selectIsAdGroupSuperAdmin()(state) || selectIsLibrarian()(state),
    saveAlertActiontype: selectSaveAlertActiontype()(state),
    unsavedChanges: selectIsMetadataFormEdited()(state),
    isProjectAdmin: makeSelectIsLoggedInUserProjectAdmin()(state),
    isAdminOrLibrarian:
      selectIsAdGroupSuperAdmin()(state) || selectIsLibrarian()(state),
    lanId: selectUserId()(state),
  }
}

const mapDispatchToProps = {
  fetchAssetMetadata,
  resetAssetMetadata,
  fetchCatalogData,
  isMetadataFormEdited,
  updateMetadata,
}

const MyComponent = (props) => {
  const classes = styles()
  return <MetadataBar {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)

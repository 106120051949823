import React from 'react'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import UnAuthorizedImg from '../images/assethub_unauthorized.png'

const useStyles = makeStyles({
  unauthorizedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '75vh',
  },
  headerTitle: {
    fontSize: '30px',
    marginBottom: '-30px',
  },
  bullseye: {
    height: '50vh',
  },
  unauthorizedText: {
    flexDirection: 'column',
    fontFamily: 'Helvetica',
    fontWeight: 'lighter',
  },
  noAccessText: {
    fontSize: '20px',
    fontWeight: '300',
  },
  padLeft: {
    paddingLeft: '18px',
  },
})

function Unauthorized() {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.unauthorizedContainer}>
        <Grid item>
          <img
            src={UnAuthorizedImg}
            alt="target_dog_logo"
            className={classes.bullseye}
          />
        </Grid>
        <Grid item>
          <Grid container className={classes.unauthorizedText}>
            <Grid item className={classes.headerTitle}>
              <h1>Doggone it!</h1>
            </Grid>
            <Grid item>
              <Typography className={classes.noAccessText}>
                Looks like you don't have the entitlement to access this
                application. Let's take care of that!
              </Typography>
              <Typography className={classes.noAccessText}>
                Follow instructions below to gain access.
              </Typography>
            </Grid>
            <Grid item>
              <h3>Target Team Members &amp; Contractors with Target Login</h3>
              <p>
                1. Login to{' '}
                <a target="_blank" href="https://myaccess.prod.target.com/">
                  myaccess.prod.target.com
                </a>
                <br />
                2. Request new access by searching the entitlement
                `APP-OAUTH2-ASSETHUB`. Once approved they will have access
                within the hour.
              </p>
            </Grid>

            <Grid item>
              <h3>Agencies &amp; External Partners</h3>
              <p>
                1. The company will need to be added to{' '}
                <a target="_blank" href="https://partnersonline.com/">
                  Partners Online
                </a>{' '}
                (POL) as a Marketing Partner or Merchant Partner. <br />
                2. A company admin will need to be chosen to manage users for
                their company in{' '}
                <a target="_blank" href="https://vmm.target.com/vmm">
                  Vendor Management and Maintenance
                </a>{' '}
                (VMM).
                <br />
                <span className={classes.padLeft}>
                  To access VMM, you must be logged into{' '}
                  <a target="_blank" href="https://partnersonline.com/">
                    partnersonline.com
                  </a>
                  {'.'}
                </span>
                <br />
                3. Each user will need to be added and set as active in VMM by
                the company admin. <br />
                4. The Target partner for the vendor will need to request
                AssetHub access for users on their behalf by reaching out to{' '}
                <a href="mailto: david.lahaye@target.com">
                  david.lahaye@target.com
                </a>
                . <br />
                5. Once approved the users will be able to launch AssetHub from
                the{' '}
                <a target="_blank" href="https://partnersonline.com/">
                  Partners Online
                </a>{' '}
                (POL) portal and begin using Assethub.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Unauthorized

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty, debounce, invert } from 'lodash'
import queryString from 'query-string'
import { DateRangePicker } from 'react-dates'
import { css } from 'react-with-styles'
import moment from 'moment'
import Moment from 'moment-timezone'
import { withEnv } from '@praxis/component-runtime-env'
import InputLabel from '@mui/material/InputLabel'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import {
  Grid,
  CircularProgress,
  Button,
  MenuItem,
  Checkbox,
  Select,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { Tune } from '@mui/icons-material'
import Toolbar from '@mui/material/Toolbar'
// import { MfeTestUI } from '../MicroFrontends'

import HeaderTitle from '../Header/HeaderTitle'
import SearchForm from './SearchForm'
import AssetList from './AssetList'
import FacetList from './FacetList'
import PageSelector from './PageSelector'
import BulkActionBar from './BulkActionBar'
import PageSizeSelector from './PageSizeSelector'
import DeleteAssetsDailog from './DeleteAssetsDailog'
import MetadataPreview from '../../containers/Metadata'
import withRouter from '../../containers/Router/WithRouter'
import {
  setDeleteAssetInProgress,
  setDownloadAssetInProgress,
  fetchAssets,
  fetchReverseImageAssets,
  fetchSearchTemplates,
  fetchTypeaheadSuggestions,
  saveAppliedFilters,
  setSortFields,
  updateDownloadAssets,
  updateFacetFilter,
  fetchMetadatadetails,
  updateSearchTerm,
  setCurrentPageSize,
  setPreviewImage,
  clearSearchAssets,
  setTemplateId,
} from './../../store/search/actions'
import {
  makeSelectIsFetchAssetsInProgress,
  selectAppliedFilters,
  selectAssetSearchResultFacets,
  selectAssetSearchResults,
  selectDeleteAssetInProgress,
  selectDownloadAssetInProgress,
  selectTemplateId,
  selectDownloadAssets,
  selectHeaderTitle,
  selectSearchTemplates,
  selectTotalSearchResults,
  selectUserEmail,
  selectIsFetchAssetsInProgress,
  selectIsReturnStrict,
  selectSortFields,
  selectPageSize,
  selectIsMetadataDownloadInProgress,
  selectSearchTerm,
  selectPreviewUrl,
} from './../../store/search/selector'
import {
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
} from '../../store/layout/selector'
import { selectIsMetadataFormEdited } from '../../store/metadata/selector'
import {
  selectIsAdGroupSuperAdmin,
  selectIsLibrarian,
  selectUserId,
} from '../../store/auth/selector'
import { resetProjectData } from '../../store/projects/actionCreator'
import {
  DOWNLOAD_ORIGINAL_VERSION,
  SEARCH_TEMPLATE_DEFAULT_VALUE,
  DEFAULT_SORT_BY_OPTION,
  SORT_OPTIONS,
  SEARCH_BOX_PLACEHOLDER,
  SEARCH_DEFAULT_FACETS,
  FACET_UPLOAD_DATE_NAME,
  SORT_BY_OPTIONS,
  FACET_WITH_PEOPLE_KEY_NAME,
  FACET_WITH_ANIMALS_KEY_NAME,
  FACET_WITH_FOOD_KEY_NAME,
  DEFAULT_COLORS,
  FACET_COLOR_KEY_NAME,
  FACET_CUSTOM_COLOR_KEY_NAME,
} from '../../constants/search'
import {
  getAppliedFilters,
  formatFilterDisplayValues,
  mapSelectedItemsToQueryParams,
  initFiltersInAppliedFilters,
  isSameDay,
} from '../../helpers/SearchHelper'
import { startDownload, updateDownloadMetricsByAssetId } from '../../helpers/bulkDownloadHelper'
import { getDeleteAssetNotificationMessage } from '../../helpers/MetadataHelper'
import {
  formatNumberToString,
  capitalizeString,
} from '../../helpers/stringHelper'
import {
  toggleRightSidenav,
  setSaveAlertAction,
} from '../../store/layout/actionCreator'
import { getAssetClassificationHierarchy } from '../../store/classification/actionCreator'
import {
  deleteRevisions,
  clearAssetRevisions,
  fetchAssetMetadata,
  setMetadataLoading,
} from '../../store/metadata/actionCreator'
import LibrarianPanel from './LibrarianPanel'
import firefly from '../../analytics/firefly'
import apiConfig from '../../config/apiConfig'

const today = moment()
const yesterday = moment().subtract(1, 'day')
const presets = [
  {
    text: 'Today',
    start: today,
    end: today,
  },
  {
    text: 'Yesterday',
    start: yesterday,
    end: yesterday,
  },
  {
    text: 'Last 7 days',
    start: moment().subtract(1, 'week'),
    end: today,
  },
  {
    text: 'Last 30 days',
    start: moment().subtract(1, 'month'),
    end: today,
  },
]

const styles = makeStyles((theme) => ({
  searchContainer: {
    paddingTop: '1%',
  },
  root: {
    flexGrow: 1,
    // overflow: 'hidden',
    margin: '3px',
  },
  startOverButton: {
    color: useTheme().palette.primary.darkBlue,
    boxShadow: 'unset',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  refreshButton: {
    color: useTheme().palette.primary.mediumGrey,
    marginRight: 5,
  },
  logoTitle: {
    [useTheme().breakpoints.between('xs', 'md')]: {
      fontSize: '28px',
    },
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    color: useTheme().palette.primary.midGrey,
    margin: 'auto auto 0 auto',
    width: '100%',
    justifyContent: 'flex-start',
  },
  logoImg: {
    [useTheme().breakpoints.between('xs', 'md')]: {
      height: '32px',
    },
    height: '36px',
    width: 'auto',
  },
  pageTemplateTitle: {
    margin: '20px',
    fontSize: '21px',
    color: useTheme().palette.primary.graphite,
  },
  resultsCount: {
    fontSize: '13px',
    color: useTheme().palette.primary.midGrey,
    margin: '8px',
  },
  resultsBox: {
    paddingRight: '0px !important',
    paddingLeft: '15px !important',
  },
  TabsContainer: {
    backgroundColor: useTheme().palette.primary.lightestGrey,
    color: useTheme().palette.primary.darkBlue,
    opacity: 'unset',
    '&:selected': {
      color: useTheme().palette.primary.graphite,
    },
  },
  indicator: {
    backgroundColor: useTheme().palette.primary.darkBlue,
  },
  noResult: {
    margin: '10px auto',
    color: useTheme().palette.primary.midGrey,
    fontSize: 21,
    fontWeight: 500,
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px 0 20px',
    alignItems: 'center',
    marginBottom: '15px',
  },
  bulkActionBar: {
    height: '50px',
    backgroundColor: useTheme().palette.primary.primaryBlue,
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'left',
    color: 'white',
    paddingLeft: '33px',
  },
  searchTerm: {
    fontWeight: 300,
    marginBottom: 0,
    fontSize: '3em',
  },
  progress: {
    margin: '10% auto',
  },
  lowerNavButtons: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 8px 0 20px',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: '15px',
  },
  filterChip: {
    textTransform: 'capitalize',
    margin: '8px 4px',
  },
  genericMessage: {
    color: useTheme().palette.primary.light,
    fontSize: '20px',
  },
  showControlsIcon: {
    paddingRight: '5px',
  },
  sortByLabel: {
    marginRight: 5,
  },
  sortByTextbox: {
    marginTop: '-7px',
  },
  floatingLabelFocusStyle: {
    color: useTheme().palette.primary.midGrey + ' !important',
  },
  floatingLabelStyle: {
    fontSize: '14px',
    color: useTheme().palette.primary.primaryBlue,
  },
  tabRoot: {
    minWidth: 'unset',
  },
  containerWrapper: {
    marginTop: 18,
    marginLeft: 19,
    zIndex: 2,
  },
  middleBar: {
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#F5F5F5',
    padding: '3px 16px 3px 16px !important',
    height: '70px',
    position: 'relative',
    zIndex: '2',
    width: '99%',
    marginTop: '0px',
    backgroundColor: 'white',
    marginLeft: '33px',
  },
  middleBarSection: {
    width: '50%',
  },
  checkboxText: {
    paddingTop: '11px',
    fontSize: '13px',
    color: useTheme().palette.primary.midGrey,
    marginLeft: '-4px',
    cursor: 'pointer',
  },
  showFilterButton: {
    height: '69px',
    width: '201px',
    border: 'none',
    backgroundColor: '#F5F5F5',
    outline: 'none',
    marginLeft: '12px',
    cursor: 'pointer',
    fontSize: 14,
  },
  showFilterGrid: {
    padding: '0px !important',
  },
  showFilterText: {
    color: '#383838',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  facetList: {
    paddingLeft: '5px !important',
    paddingTop: '0px !important',
    position: 'sticky',
    overflow: 'scroll',
    height: '80vh',
    zIndex: '2',
    left: 0,
    top: '179px',
    background: '#f5f5f5',
  },
  PresetDateRangePicker_panel: {
    padding: '0 22px 11px 22px',
  },
  PresetDateRangePicker_button: {
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    background: 'none',
    border: `2px solid ${useTheme().palette.primary.primaryBlue}`,
    color: useTheme().palette.primary.primaryBlue,
    padding: '4px 12px',
    marginRight: 8,
    font: 'inherit',
    fontWeight: 700,
    lineHeight: 'normal',
    overflow: 'visible',
    boxSizing: 'border-box',
    cursor: 'pointer',
    ':active': {
      outline: 0,
    },
  },
  PresetDateRangePicker_button__selected: {
    color: useTheme().palette.primary.whiteSmoke,
    background: useTheme().palette.primary.primaryBlue,
  },
  dateRange: {
    position: 'absolute',
    zIndex: '5',
    width: '335px',
    paddingLeft: '97px',
  },
  dateRangePicker: {
    position: 'relative',
    marginLeft: '20px',
    paddingTop: '0px !important',
    paddingBottom: '7px !important',
    paddingLeft: '24px !important',
    marginTop: '15px',
    position: 'sticky',
    zIndex: '5',
    width: '100%',
    backgroundColor: 'white',
    top: 0,
  },
  indexSearch: {
    display: 'flex',
    float: 'right',
  },
  controls: {
    paddingTop: '0px !important',
  },
  searchBar: {
    paddingLeft: '30px !important',
    paddingTop: '50px !important',
    position: 'sticky',
    zIndex: '5',
    width: '100%',
    backgroundColor: 'white',
    top: 0,
  },
  filterBar: {
    position: 'sticky',
    top: '50px',
    zIndex: 4,
  },
  fileTypeItem: {
    padding: 0,
  },
  listText: {
    fontSize: '14px',
  },
  rightList: {
    marginLeft: '150px',
    position: 'absolute',
    zIndex: '2',
    backgroundColor: 'white',
    width: '150px',
    borderTop: '2px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderRight: '2px solid #e5e5e5',
    borderRadius: '1px 10px 10px 1px',
  },
  leftList: {
    position: 'absolute',
    zIndex: '2',
    backgroundColor: 'white',
    width: '150px',
    borderTop: '2px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderLeft: '2px solid #e5e5e5',
    borderRadius: '10px 1px 1px 10px',
  },
  fileTypeContiainer: {
    zIndex: '2',
    position: 'absolute',
  },
  dropdownFilter: {
    textTransform: 'none',
    fontSize: '13px',
    letterSpacing: 'normal',
    color: '#000000',
    opacity: '0.8',
  },
  selectFilter: {
    height: '40px',
    fontWeight: '400',
    fontSize: '13px',
    marginRight: '10px',
  },
  assetContainer: {
    marginTop: '5px',
    overflow: 'scroll',
    maxHeight: '78vh',
  },
}))

const initialState = {
  currentPage: 0,

  displayDeleteAssetConfirmationModal: false,
  initFilters: {},
  selectedItems: [],
  isDeleteInitiated: false,
  isDownloadInitiated: false,
  downloadAssets: {},
  showModal: false,
  currentModalAsset: 0,
  templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
  strict: true,
  checkAll: false,
  controlsOpen: true,
  pageActionType: '',
  pageActionOptions: {},
  doesSearchExist: true,
  isModal: false,
}

const createSortByMenuItems = (sortByOptions = {}) =>
  Object.keys(sortByOptions).map((key) => (
    <MenuItem data-cy={key} key={key} value={key}>
      {sortByOptions[key]}
    </MenuItem>
  ))

const getColorFacetValue = (filterValue = '') =>
  Object.keys(DEFAULT_COLORS)[
    Object.values(DEFAULT_COLORS).indexOf((filterValue || '').toUpperCase())
  ] || filterValue

const createColorChipContent = (filterValue = '', filterName = '') => {
  const colorNamesCodes = invert(DEFAULT_COLORS)
  let colorObj = {
    background: 'transparent',
    height: '20px',
    width: '20px',
    marginBottom: '2px',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
    borderRadius: '50%',
    transition: 'box-shadow 100ms ease 0s',
    contentVisibility: 'hidden',
  }
  colorObj['boxShadow'] =
    (colorNamesCodes[filterValue] || filterValue) + ' 0px 0px 0px 16px inset'
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end' }}
      data={{ detail: filterValue || '', label: filterName }}
    >
      <div data-cy="filterChipFacet" title={filterValue} style={colorObj}>
        {filterName}
      </div>
      <span
        style={{ textTransform: 'upperCase', marginLeft: '2px' }}
      >{`${getColorFacetValue(filterValue)}`}</span>
    </div>
  )
}

const createSelctedFilters = (appliedFilters = {}) => {
  let selectedFilters = []
  for (const filter in appliedFilters) {
    if (
      [
        FACET_UPLOAD_DATE_NAME,
        FACET_COLOR_KEY_NAME,
        FACET_CUSTOM_COLOR_KEY_NAME,
      ].indexOf(filter) === -1
    ) {
      for (let i = 0; i < appliedFilters[filter].length; i++) {
        const isDisplay =
          filter === FACET_WITH_PEOPLE_KEY_NAME ||
          filter === FACET_WITH_ANIMALS_KEY_NAME ||
          filter === FACET_WITH_FOOD_KEY_NAME
            ? true
            : false
        selectedFilters.push(
          <div
            data={{ detail: appliedFilters[filter][i] || '', label: filter }}
          >
            <span
              data-cy="filterChipFacet"
              style={
                isDisplay
                  ? { fontWeight: 600, display: 'none' }
                  : { fontWeight: 600 }
              }
            >
              {filter}
            </span>
            <span style={{ textTransform: 'capitalize' }}>
              {isDisplay
                ? appliedFilters[filter][i]
                : ': ' + appliedFilters[filter][i]}
            </span>
          </div>
        )
      }
    } else if (
      [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(filter) !== -1
    ) {
      for (let i = 0; i < appliedFilters[filter].length; i++) {
        selectedFilters.push(
          createColorChipContent(appliedFilters[filter][i], filter)
        )
      }
    }
  }
  return selectedFilters
}

export class Search extends React.Component {
  constructor(props) {
    super(props)
    // Bind keydown event to class function
    // document.addEventListener("keydown", this.onArrowKeyPress, false);
    const {
      downloadAssetList = {},
      downloadInProgress,
      templateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      searchTerm = '',
      sortFields = {},
      pageSize = 21,
      appliedFilters,
    } = props
    const { import_date = [] } = appliedFilters
    this.state = {
      ...initialState,
      searchTerm: searchTerm,
      sortBy: this.getSortFieldsByKey(sortFields) || DEFAULT_SORT_BY_OPTION,
      currentPageSize: pageSize || 21,
      downloadAssets: { ...downloadAssetList },
      isDownloadInitiated: downloadInProgress,
      downloadAssetType: DOWNLOAD_ORIGINAL_VERSION,
      templateId: templateId,
      assetRevId: '',
      originalAssetId: '',
      metaDataSingleAsset: {},
      isRefresh: false,
      isRightOrLeftArrowClicked: false,
      searchParamObject: {},
      isRemove: false,
      filtersVisible: true,
      startDate: import_date[0] || null,
      endDate: import_date[1] || null,
      focusedInput: null,
      showDates: false,
      showFileTypes: false,
      fileTypes: [],
      focus: 'startDate',
      index: 'test_target',
      searchAssetList: [],
    }
  }

  componentDidMount() {
    const {
      match = {},
      router = {},
      fetchAssetMetadata = () => {},
      getAssetClassificationHierarchy = () => {},
    } = this.props
    const { params = {} } = router
    const { assetOriginalId = '', assetRevId = '' } = params
    if (assetRevId !== '' || assetOriginalId !== '') {
      this.setState({ isRefresh: true, isModal: true, showModal: true })
      fetchAssetMetadata(
        assetRevId,
        (response) => {
          const assetDetails = response || {}
          const {
            urls: { previewFilePath: fileUrl = '' } = {},
            metadata: metadataInfo = {},
            assetId = '',
          } = assetDetails
          const {
            fileMetadata: { fileName = '', fileSizeMb = 0 },
          } = metadataInfo
          const assetMetadata = {
            fileName,
            fileUrl: '',
            assetId,
            assetDetails,
            fileSizeMb,
          }
          this.setState({
            showModal: true,
            isModal: true,
            assetRevId: assetRevId,
            originalAssetId: assetOriginalId,
            metaDataSingleAsset: assetMetadata,
            isRefresh: false,
          })
        },
        () => {}
      )
    } else {
      getAssetClassificationHierarchy()
      this.fetchAllAssetData()
    }
  }

  getSortFieldsByKey = (sortFields = {}) =>
    Object.keys(SORT_BY_OPTIONS).find(
      (key) => SORT_BY_OPTIONS[key] === sortFields
    )

  fetchAllAssetData = () => {
    const {
      fetchSearchTemplates = () => {},
      resetProjectData = () => {},
      isAdminOrLibrarian = false,
    } = this.props
    let { searchTerm = '', isNewSearch = true, currentPage = 0 } = this.state
    // let { history: { location: { search = '' } = {} } = {} } = this.props
    let { router: { location: { search = '' } = {} } = {} } = this.props
    let parsedstringFromUrl = queryString.parse(search)
    !Object.keys(parsedstringFromUrl).length &&
      this.fetchData({ searchTerm, currentPage, isNewSearch })
    isAdminOrLibrarian && fetchSearchTemplates()
    resetProjectData()
  }

  saveFile = (file, filtersVisible = true) => {
    this.setState({
      imageSearch: file,
      filtersVisible: filtersVisible,
    })
    this.fetchReverseImageData({ file })
    const preview = URL.createObjectURL(file)
    this.props.setPreviewImage(preview)
  }

  componentWillUnmount() {
    //Hides right side nav on search unmount
    const {
      toggleRightSidenav = () => {},
      rightSideNavComponent = '',
      isToggleRightSidenav,
      updateSearchTerm = () => {},
      setCurrentPageSize = () => {},
    } = this.props
    const { searchTerm = '', currentPageSize = 21 } = this.state
    if (isToggleRightSidenav) toggleRightSidenav(rightSideNavComponent, false)
    updateSearchTerm(searchTerm)
    setCurrentPageSize(currentPageSize)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPageSize !== this.state.currentPageSize) {
      this.setState({
        selectedItems: [],
      })
    }
    //selected items updating in store
    const {
      deleteAssetInProgress,
      downloadAssetList = {},
      searchAssets = [],
    } = this.props
    const {
      downloadAssetList: prevDownloadAssetList = {},
      deleteAssetInProgress: prevDeleteAssetInProgress,
    } = prevProps
    const { downloadAssets = {}, imageSearch = '' } = this.state
    const downloadAssetListCount = Object.keys(downloadAssetList).length

    const isSelectedItemsExists =
      Object.keys(downloadAssets).length !== downloadAssetListCount
    const isStateUpdated =
      Object.keys(prevDownloadAssetList).length !== downloadAssetListCount

    if (isSelectedItemsExists && !isStateUpdated) {
      this.props.updateDownloadAssets(downloadAssets)
    } else if (isStateUpdated && downloadAssetListCount === 0) {
      this.setState({
        downloadAssets: {},
        selectedItems: [],
      })
    }

    if (prevDeleteAssetInProgress && !deleteAssetInProgress) {
      this.setState({
        isDeleteInitiated: false,
        downloadAssets: {},
        selectedItems: [],
        displayDeleteAssetConfirmationModal: false,
      })
      this.fetchData({})
    }
    if (imageSearch && prevProps.searchAssets !== this.props.searchAssets) {
      this.setState({
        searchAssetList: searchAssets?.slice(0, 100),
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.downloadInProgress && state.isDownloadInitiated) {
      return {
        isDownloadInitiated: props.downloadInProgress,
        downloadAssets: props.downloadAssetList,
        selectedItems: [],
        checkAll: false,
      }
    }
  }

  getNumberOfPages = () => {
    const results = this.props.totalResults
    return Math.ceil(results / this.state.currentPageSize)
  }

  prevPage = (e) => {
    const {
      currentPage = 0,
      downloadAssets = {},
      searchTerm = '',
      templateId,
    } = this.state
    const { appliedFilters = {}, userId, searchImagePreview = '' } = this.props
    e.preventDefault()
    if (currentPage > 0) {
      const pageNumber = currentPage - 1
      searchImagePreview
        ? this.fetchReverseImageData({ currentPage: pageNumber })
        : this.fetchData({ searchTerm, currentPage: pageNumber })
      this.props.updateDownloadAssets(downloadAssets)
      let filters = ''
      for (const [key, value] of Object.entries(appliedFilters)) {
        filters = filters.concat(`${key}: ${value}, `)
      }
      firefly.trackSearchPageChange(
        searchTerm,
        filters.trim(),
        userId,
        pageNumber,
        currentPage,
        templateId
      )
    }
  }

  nextPage = (e) => {
    const {
      currentPage = 0,
      downloadAssets = {},
      searchTerm = '',
      templateId,
    } = this.state
    const { appliedFilters = {}, userId, searchImagePreview = '' } = this.props
    e.preventDefault()
    if (currentPage < this.getNumberOfPages() - 1) {
      const pageNumber = currentPage + 1
      searchImagePreview
        ? this.fetchReverseImageData({ currentPage: pageNumber })
        : this.fetchData({ searchTerm, currentPage: pageNumber })
      this.props.updateDownloadAssets(downloadAssets)
      let filters = ''
      for (const [key, value] of Object.entries(appliedFilters)) {
        filters = filters.concat(`${key}: ${value}, `)
      }
      firefly.trackSearchPageChange(
        searchTerm,
        filters.trim(),
        userId,
        pageNumber,
        currentPage,
        templateId
      )
    }
  }

  onPageSizeChange = (event) => {
    const {
      searchTerm = '',
      currentPageSize,
      templateId,
      currentPage,
    } = this.state
    const { appliedFilters = {}, userId, searchImagePreview = '' } = this.props
    const pageSize = event.target.value
    const newPageNumber = 0
    if (pageSize !== currentPageSize) {
      this.setState({
        currentPageSize: pageSize,
        selectedItems: [],
        checkAll: false,
      })
      if (searchImagePreview) {
        this.fetchReverseImageData({
          currentPage: newPageNumber,
          templateId,
          currentPageSize: pageSize,
        })
      } else {
        this.fetchData({
          searchTerm,
          currentPage: newPageNumber,
          templateId,
          currentPageSize: pageSize,
        })
      }
      let filters = ''
      for (const [key, value] of Object.entries(appliedFilters)) {
        filters = filters.concat(`${key}: ${value}, `)
      }
      firefly.trackSearchResultsPerPageChange(
        searchTerm,
        filters.trim(),
        userId,
        currentPage,
        pageSize,
        templateId
      )
    }
  }

  fetchData = ({
    searchTerm = this.state.searchTerm,
    currentPage = this.state.currentPage,
    templateId = this.state.templateId,
    currentPageSize = this.state.currentPageSize,
    strict = this.state.strict,
    chipFilters = {},
    ignoreAppliedFilters = false,
    isNewSearch,
  }) => {
    const {
      appliedFilters = {},
      fetchAssets,
      sortFields = {},
      userId = '',
    } = this.props
    const { index: indexBy = '' } = this.state
    const filters = ignoreAppliedFilters
      ? {}
      : { ...appliedFilters, ...chipFilters }
    fetchAssets({
      index: indexBy,
      filters,
      isNewSearch,
      pageNumber: currentPage,
      searchTerm,
      size: currentPageSize,
      templateId,
      strict,
      sort_fields: sortFields,
      userId,
    })
    this.setState((prevState) => {
      if (prevState.searchTerm === searchTerm) {
        return { searchTerm, currentPage }
      } else {
        return { searchTerm, currentPage, strict: true }
      }
    })
  }

  fetchReverseImageData = ({
    file = this.state.imageSearch,
    currentPage = this.state.currentPage,
    currentPageSize = this.state.currentPageSize,
  }) => {
    const {
      appliedFilters = {},
      fetchReverseImageAssets,
      sortFields = {},
      userId = '',
    } = this.props
    const { index: indexBy = '' } = this.state
    const filters = appliedFilters
    fetchReverseImageAssets({
      index: indexBy,
      filters,
      pageNumber: currentPage,
      size: currentPageSize,
      sort_fields: sortFields,
      userId,
      file,
    })
    this.setState({ currentPage, selectedItems: [] })
  }

  toggleDeleteAssetsConfirmation = (dispalyModal = true) => {
    this.setState({ displayDeleteAssetConfirmationModal: dispalyModal })
  }

  onDownloadClicked = (e) => {
    const downloadAssetType = e.currentTarget.id
    this.setState({ isDownloadInitiated: true, downloadAssetType })
    const { downloadAssets = {}, currentPage } = this.state
    const { dispatch, setDownloadAssetInProgress, userId } = this.props
    startDownload(
      { ...downloadAssets },
      dispatch,
      downloadAssetType,
      userId,
      currentPage,
      true,
      'search'
    )
    setDownloadAssetInProgress(true)
  }

  onDeleteAssets = () => {
    const { downloadAssets = {} } = this.state
    const { deleteRevisions, setDeleteAssetInProgress } = this.props
    const deleteMasterIds = []
    const deleteAssetIds = []
    if (!isEmpty(downloadAssets)) {
      this.setState({
        isDeleteInitiated: true,
        displayDeleteAssetConfirmationModal: false,
      })
      Object.values(downloadAssets).forEach((asset) => {
        if (asset.assetMasterId) {
          deleteMasterIds.push(asset.assetMasterId)
        } else {
          deleteAssetIds.push(asset.assetId)
        }
      })

      const assetIds = {}
      if (deleteMasterIds.length > 0) {
        assetIds.asset_master_uoi_ids = deleteMasterIds
      }
      if (deleteAssetIds.length > 0) {
        assetIds.asset_uoi_ids = deleteAssetIds
      }

      if (Object.keys(assetIds).length > 0) {
        setDeleteAssetInProgress(true)
        deleteRevisions(
          assetIds,
          getDeleteAssetNotificationMessage(
            Object.values(downloadAssets).length
          )
        )
      }
    }
  }

  getDownloadAssets = (assetId = '', downloadAssets = {}) => {
    delete downloadAssets[assetId]
    return downloadAssets
  }

  onSelection = (checked, ref, asset) => {
    const { selectedItems } = this.state
    const { id = '', href = '', title = '', dataset = {} } = ref.current
    const {
      assetmasterid = '',
      contentid = '',
      filesizemb = '',
      importdate = '',
    } = dataset
    const { render_urls = {} } = asset
    let newDate = ''
    if (importdate) {
      const date = Date.parse(importdate)
      newDate = new Date(date).toISOString()
    }

    if (checked) {
      if (
        !selectedItems.find(
          (item) => !isEmpty(item.current) && item.current.id === id
        )
      ) {
        const downloadAsset = {
          [id]: {
            assetId: id,
            assetMasterId: assetmasterid,
            fileName: title,
            filePath: href,
            contentId: contentid,
            fileSizeMb: filesizemb,
            importDate: newDate,
            render_urls,
          },
        }
        this.setState((prevState) => ({
          selectedItems: [...prevState.selectedItems, ref],
          downloadAssets: { ...prevState.downloadAssets, ...downloadAsset },
        }))
      }
    } else {
      this.setState((prevState) => ({
        selectedItems: prevState.selectedItems.filter(
          (item) => !isEmpty(item.current) && item.current.id !== id
        ),
        downloadAssets: this.getDownloadAssets(id, prevState.downloadAssets),
      }))
    }
  }

  onClickDownloadAsset = (assetDetails) => {
    const { currentPage } = this.state
    const { userId } = this.props
    const { asset_id = '', render_urls: {
      original_asset_url = ''
    } = {} } = assetDetails
    if (original_asset_url && !original_asset_url.includes('streaming')) {
      updateDownloadMetricsByAssetId(asset_id)
    }
    firefly.trackAssetCardDownloads(assetDetails, userId, currentPage)
  }

  resetPageNumber = () => this.setState({ currentPage: 0 })

  resetAll = () => {
    const {
      saveAppliedFilters = () => {},
      setSortFields = () => {},
      setCurrentPageSize = () => {},
    } = this.props
    const { initFilters, doesSearchExist } = this.state
    saveAppliedFilters(initFilters)
    setSortFields()
    this.setState({
      ...initialState,
      initFilters,
      doesSearchExist,
      sortBy: DEFAULT_SORT_BY_OPTION,
    })
  }

  resetAllOnStartOver = () => {
    const {
      saveAppliedFilters = () => {},
      setSortFields = () => {},
      updateSearchTerm = () => {},
      setCurrentPageSize = () => {},
    } = this.props
    const { initFilters, doesSearchExist } = this.state
    saveAppliedFilters(initFilters)
    setSortFields()
    updateSearchTerm()
    setCurrentPageSize()
    this.setState({
      ...initialState,
      initFilters,
      doesSearchExist,
      searchTerm: '',
      sortBy: DEFAULT_SORT_BY_OPTION,
      currentPageSize: 21,
    })
  }

  setInitFilters = (initFilters, searchTerm) => {
    this.setState({ initFilters, searchTerm })
  }

  prevAsset = (e) => {
    const { currentModalAsset, currentPage, currentPageSize = 21 } = this.state
    const { clearAssetRevisions = () => {} } = this.props
    clearAssetRevisions()
    if (currentModalAsset > 0) {
      this.setState(() => ({
        currentModalAsset: currentModalAsset - 1,
        isRightOrLeftArrowClicked: true,
      }))
    } else if (currentModalAsset === 0 && currentPage > 0) {
      this.prevPage(e)
      setTimeout(
        () =>
          this.setState({
            currentModalAsset: currentPageSize - 1,
            isRightOrLeftArrowClicked: true,
          }),
        1000
      )
    }
  }

  nextAsset = (e) => {
    const { currentModalAsset, currentPage } = this.state
    const { searchAssets = [], clearAssetRevisions = () => {} } = this.props
    clearAssetRevisions()
    if (currentModalAsset < searchAssets.length - 1) {
      this.setState(() => ({
        currentModalAsset: currentModalAsset + 1,
        isRightOrLeftArrowClicked: true,
      }))
    } else if (
      currentModalAsset >= searchAssets.length - 1 &&
      currentPage < this.getNumberOfPages() - 1
    ) {
      this.nextPage(e)
      setTimeout(
        () =>
          this.setState({
            currentModalAsset: 0,
            isRightOrLeftArrowClicked: true,
          }),
        1000
      )
    }
  }

  getAssetMetadata = (assetRevId = '', assetOriginalId = '') => {
    const {
      router = {},
      fetchAssetMetadata = () => {},
      setMetadataLoading = () => {},
    } = this.props
    setMetadataLoading(true)
    fetchAssetMetadata(
      assetRevId,
      (response) => {
        const assetDetails = response || {}
        const {
          render_urls: { preview_asset_url: fileUrl = '' },
          metadata: metadataInfo = {},
          asset_id: assetId = '',
        } = assetDetails
        const {
          file_metadata: {
            file_name: fileName = '',
            file_size_mb: fileSizeMb = 0,
          } = {},
        } = metadataInfo
        const assetMetadata = {
          fileName,
          fileUrl,
          assetId,
          assetDetails,
          fileSizeMb,
        }
        setMetadataLoading(false)
        this.setState(
          {
            assetRevId: assetRevId,
            originalAssetId: assetOriginalId,
            metaDataSingleAsset: assetMetadata,
          },
          () => {
            router.navigate(`/search/${assetRevId}/${assetOriginalId}`)
          }
        )
      },
      () => {
        setMetadataLoading(false)
      }
    )
  }

  onAssetSelect = (assetIdx, assetItem = {}) => {
    const { asset_details: metaData = {} } = assetItem
    const {
      asset_id: assetRevId = '',
      original_asset_id: assetOriginalId = '',
    } = metaData
    this.setState(
      {
        isModal: true,
        showModal: true,
        currentModalAsset: assetIdx,
      },
      () => {
        this.getAssetMetadata(assetRevId, assetOriginalId)
      }
    )
  }

  onTemplateChange = (templateId) => {
    const newTemplateId = templateId || SEARCH_TEMPLATE_DEFAULT_VALUE
    const { templateId: oldTemplateId = '' } = this.state
    const { setTemplateId = () => {} } = this.props
    if (oldTemplateId !== newTemplateId) {
      setTemplateId(newTemplateId)
      this.setState({
        pageActionType: 'tabschange',
        pageActionOptions: { templateId: newTemplateId },
        templateId: newTemplateId,
      })
    }
  }

  onSortChange = (event) => {
    const {
      searchTerm = '',
      currentPage = 0,
      currentPageSize = 21,
      index: indexBy = '',
      file = this.state.imageSearch,
    } = this.state
    const {
      appliedFilters = {},
      templateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      fetchAssets,
      setSortFields,
      userId,
      searchImagePreview = '',
      fetchReverseImageAssets,
    } = this.props
    const sortByOption = typeof event === 'object' ? event.target.value : event
    const sort_fields = SORT_BY_OPTIONS[sortByOption]
    setSortFields(sort_fields)
    if (searchImagePreview) {
      fetchReverseImageAssets({
        index: indexBy,
        filters: appliedFilters,
        pageNumber: currentPage,
        size: currentPageSize,
        templateId: templateId,
        sort_fields,
        file,
      })
    } else {
      fetchAssets({
        index: indexBy,
        filters: appliedFilters,
        searchTerm,
        size: currentPageSize,
        templateId: templateId,
        sort_fields,
      })
    }
    this.setState({
      sortBy: sortByOption,
      pageActionType: 'sort',
      pageActionOptions: { sortByOption: sortByOption },
    })
    let filtersTrack = ''
    for (const [key, value] of Object.entries(appliedFilters)) {
      filtersTrack = filtersTrack.concat(`${key}: ${value}, `)
    }
    firefly.trackSearchSort(
      searchTerm,
      filtersTrack.trim(),
      userId,
      currentPage,
      sortByOption
    )
  }

  setFacetHandlers = (facetName, handleTagDelete, handleTagsClear) => {
    if (this.ACFacets && this.ACFacets[facetName]) {
      this.ACFacets[facetName] = { handleTagDelete, handleTagsClear }
    }
  }

  onStartOver = () => {
    const { router = {} } = this.props
    router.navigate('?')
  }

  onClearAppliedFilters = () => {
    this.setState({
      templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
      pageActionType: 'clear',
      pageActionOptions: { clearBy: 'refresh_' + Math.random() },
      searchParamObject: {},
      startDate: null,
      endDate: null,
    })
  }

  onFilterChange = (filterName, filterValue, remove) => {
    let newInitFilters = this.state.initFilters
    if (remove) {
      newInitFilters = Object.fromEntries(
        Object.entries(newInitFilters)
          .map((array) => {
            if (
              array[0] === SEARCH_DEFAULT_FACETS[filterName] &&
              array[1].indexOf(filterValue) > -1
            ) {
              let newValues = array[1].filter((elem) => elem !== filterValue)
              if (newValues.length > 0) {
                return [array[0], newValues]
              } else return [array[0]]
            }
            return array
          })
          .filter((array) => array[1] !== undefined)
      )
    }
    this.setState({
      pageActionType: 'filter',
      pageActionOptions: {
        filterName: filterName,
        filterValue: filterValue,
        type: remove ? 'REMOVE' : '',
      },
      initFilters: newInitFilters,
      isRemove: true,
    })
  }

  onSearchTemplateChange = (templateId = SEARCH_TEMPLATE_DEFAULT_VALUE) => {
    const { searchTerm = '' } = this.state
    const { appliedFilters = {}, userId } = this.props
    let filters = ''
    for (const [key, value] of Object.entries(appliedFilters)) {
      filters = filters.concat(`${key}: ${value}, `)
    }
    this.setState({ templateId })
    firefly.trackLibrarianTemplateChange(
      searchTerm,
      filters.trim(),
      userId,
      templateId
    )
  }

  onClearSelection = () => {
    this.setState((state) => ({
      checkAll: false,
      selectedItems: [],
      downloadAssets: {},
    }))
  }

  onSelectAll = () => {
    this.setState((state) => ({ checkAll: !state.checkAll }))
  }

  toggleControls = () => {
    this.setState((state) => ({ controlsOpen: !state.controlsOpen }))
  }

  selectAllCheck = () => {
    const { searchAssets = [], searchImagePreview = '' } = this.props
    const {
      checkAll = '',
      downloadAssets = [],
      searchAssetList = [],
    } = this.state
    const searchPageData = searchImagePreview ? searchAssetList : searchAssets
    if (checkAll) {
      const filteredData =
        searchPageData.filter((asset) => {
          const { asset_details = {} } = asset
          const { asset_id = '' } = asset_details
          if (
            Object.keys(downloadAssets).length &&
            !!downloadAssets[asset_id]
          ) {
            return asset
          }
          return null
        }) || []
      if (filteredData.length === searchPageData.length) {
        return true
      }
      return false
    }
    return false
  }

  doesSearchExist = (bool) => {
    let newState = {}
    newState['doesSearchExist'] = bool
    newState['controlsOpen'] = bool
    this.setState(newState)
  }

  downloadMetadata = (e) => {
    const downloadAssetType = e.currentTarget.id
    let { downloadAssets } = this.state
    let { fetchMetadatadetails } = this.props
    this.setState({ downloadAssetType })
    let selected_file_ids = Object.values(downloadAssets).map(
      (asset) => asset.assetId
    )
    fetchMetadatadetails(selected_file_ids)
  }

  handleOriginalAssetId = (assetOrgId = '', assetRevId = '') => {
    const { router = {} } = this.props
    this.setState(
      {
        assetRevId: assetRevId,
        originalAssetId: assetOrgId,
      },
      () => {
        router.navigate(`/search/${assetRevId}/${assetOrgId}`)
      }
    )
  }

  setSearchParamObject = (searchParamObject = {}) =>
    this.setState({ searchParamObject })

  closeMetaDataPreview = (isArrowShow = true) => {
    const { router = {} } = this.props
    const { searchParamObject = {} } = this.state
    const newQueryString = mapSelectedItemsToQueryParams(searchParamObject)
    router.navigate(`/search?${newQueryString}`)
    this.setState(
      {
        showModal: false,
        isModal: false,
        originalAssetId: '',
        assetRevId: '',
        metaDataSingleAsset: {},
        isRefresh: false,
        isRightOrLeftArrowClicked: false,
      }
      // Commenting as back to search from metadata shouldnt fetch new data
      // () => {
      //   if (!isArrowShow) {
      //     this.fetchAllAssetData()
      //   }
      // }
    )
  }

  filterClick = () => {
    this.setState((state) => ({ filtersVisible: !state.filtersVisible }))
  }

  onCalenderDaysButtonClick = (start, end) => {
    const dateOfStart = start !== null && start !== false ? start : null
    const dateOfEnd = end !== null && end !== false ? end : null
    this.setState({ startDate: dateOfStart, endDate: dateOfEnd }, () => {
      if (dateOfStart === null && dateOfEnd === null) {
        this.updateFilter(FACET_UPLOAD_DATE_NAME, [], { type: 'REMOVE' })
      } else if (dateOfStart !== null && dateOfEnd !== null) {
        this.updateFilter(
          FACET_UPLOAD_DATE_NAME,
          [dateOfStart.format('MM-DD-YYYY'), dateOfEnd.format('MM-DD-YYYY')],
          { update: true }
        )
        this.showDates()
      }
    })
  }

  updateFilter = (
    filterName = '',
    filterValue = '',
    filterOptions = { update: false, type: '', formatter: undefined }
  ) => {
    const {
      updateFacetFilter,
      templateId = '',
      initFilters = {},
      appliedFilters = {},
      history,
      router = {},
      userId = '',
      setIsRemove = () => {},
    } = this.props

    const isFilterApplied = this.state[filterValue]
    const { update, type } = filterOptions
    const { sortBy, searchTerm = '', currentPageSize = 21 } = this.state
    const sort_fields = SORT_BY_OPTIONS[sortBy]
    const initFiltersInPageFilters = initFiltersInAppliedFilters(
      initFilters,
      appliedFilters
    )
    if (
      initFiltersInPageFilters.indexOf(SEARCH_DEFAULT_FACETS[filterName]) !==
        -1 &&
      initFiltersInAppliedFilters(initFilters, appliedFilters).length === 0
    ) {
      router.navigate('?')
      return
    }
    if (type === 'REMOVE') {
      if (
        this.ACFacets &&
        this.ACFacets[filterName] &&
        this.ACFacets[filterName].handleTagDelete
      ) {
        this.ACFacets[filterName].handleTagDelete(filterValue, this)
      }
      if (filterName === FACET_UPLOAD_DATE_NAME) {
        this.setState({ startDate: null, endDate: null })
      }
      setIsRemove()
    }

    this.setState({ [filterValue]: !isFilterApplied })

    let actionType = isFilterApplied ? 'REMOVE' : 'ADD'

    if (update) {
      actionType = 'UPDATE'
    } else if (type) {
      actionType = type
    }

    const payload = {
      actionType,
      filterName,
      filterValue,
      searchTerm,
      size: currentPageSize,
      sort_fields,
      templateId: templateId,
    }
    this.resetPageNumber()
    updateFacetFilter(payload)

    firefly.trackClick(
      'search',
      actionType,
      searchTerm,
      `${filterName}: ${filterValue}`,
      userId,
      templateId
    )
  }

  renderDatePresets = () => {
    const { classes = {} } = this.props
    const { startDate, endDate } = this.state
    return (
      <div {...css(classes.PresetDateRangePicker_panel)}>
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate)
          return (
            <button
              key={text}
              {...css(
                classes.PresetDateRangePicker_button,
                isSelected && classes.PresetDateRangePicker_button__selected
              )}
              type="button"
              onClick={() => {
                this.onCalenderDaysButtonClick(start, end)
              }}
            >
              {text}
            </button>
          )
        })}
      </div>
    )
  }

  showDates = () => {
    this.setState((state) => ({
      showDates: !state.showDates,
      focus: 'startDate',
    }))
  }

  showFileTypes = () => {
    this.setState((state) => ({ showFileTypes: !state.showFileTypes }))
    if (this.props.facets['File Type'] != null) {
      this.setState({ fileTypes: Object.keys(this.props.facets['File Type']) })
    }
  }

  closeDropdowns = () => {
    const { showDates, showFileTypes } = this.state
    if (showDates === true || showFileTypes === true) {
      this.setState({ showDates: false, showFileTypes: false })
    }
  }

  closeBulkActionBar = () => {
    this.setState({ downloadAssets: {} })
  }

  clearImageSearch = (e) => {
    setPreviewImage('')
    const { router, userId = '', clearSearchAssets } = this.props
    this.setSearchParamObject()
    clearSearchAssets()
    this.resetAll()
    this.onClearAppliedFilters()
    firefly.trackClick(
      'search',
      'ADD',
      '',
      '',
      userId,
      SEARCH_TEMPLATE_DEFAULT_VALUE
    )
    const newQueryString = mapSelectedItemsToQueryParams({})
    router.navigate(`?${newQueryString}`)
    this.fetchData({
      searchTerm: '',
      currentPage: 0,
      ignoreAppliedFilters: true,
      isNewSearch: true,
      templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
    })
  }

  handleChange = (event) => {
    const {
      searchTerm = '',
      currentPage = 0,
      currentPageSize = 21,
    } = this.state
    const {
      appliedFilters = {},
      templateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      fetchAssets,
      setSortFields,
      userId,
      sortFields = {},
    } = this.props

    fetchAssets({
      index: event.target.value,
      filters: appliedFilters,
      searchTerm,
      size: currentPageSize,
      templateId: templateId,
      sort_fields: sortFields,
    })
    this.setState({
      index: event.target.value,
    })
  }

  loadMoreAssets = () => {
    const { searchAssetList = [] } = this.state
    const { searchAssets = [] } = this.props
    const startIndex = searchAssetList.length
    const endIndex =
      searchAssetList.length + 100 < searchAssets.length
        ? searchAssetList.length + 100
        : searchAssets.length
    const newAssets = [
      ...searchAssetList,
      ...searchAssets.slice(startIndex, endIndex),
    ]
    this.setState({
      searchAssetList: newAssets,
    })
  }

  render() {
    const {
      currentPage,
      currentPageSize,
      currentModalAsset,
      displayDeleteAssetConfirmationModal,
      downloadAssetType,
      showModal = false,
      downloadAssets = {},
      searchTerm = '',
      templateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      checkAll,
      controlsOpen,
      initFilters,
      doesSearchExist,
      isDownloadInitiated,
      sortBy = DEFAULT_SORT_BY_OPTION,
      pageActionType = '',
      pageActionOptions = {},
      isModal = false,
      originalAssetId = '',
      metaDataSingleAsset = {},
      isRefresh = false,
      isRightOrLeftArrowClicked = false,
      isRemove = false,
      filtersVisible = true,
      showDates = false,
      showFileTypes = false,
      fileTypes = [],
      startDate,
      endDate,
      focusedInput,
      focus,
      imageSearch,
      searchAssetList = [],
    } = this.state
    const {
      facets = {},
      classes = {},
      history = {},
      router = {},
      isFetching,
      headerTitle = '',
      totalResults = [],
      searchAssets = [],
      appliedFilters = {},
      searchTemplates = {},
      saveAppliedFilters,
      isFetchingWithResults,
      fetchTypeaheadSuggestions,
      isToggleRightSidenav,
      rightSideNavComponent,
      toggleRightSidenav,
      setSaveAlertAction,
      isAdminOrLibrarian = false,
      isMetadataFormEdited,
      userId = '',
      isMetadataDownloadInitiated,
      env = {},
      searchImagePreview = '',
      setPreviewImage,
    } = this.props
    const { ENABLE_INDEX_SEARCH = false } = env
    const appliedFiltersForDisplay = formatFilterDisplayValues(
      getAppliedFilters(appliedFilters)
    )
    const debouncedFetchTypeaheadSuggestions = debounce(
      fetchTypeaheadSuggestions,
      500
    )
    const selectedItems = Object.keys(downloadAssets)
    const selectedAssetCount = selectedItems.length
    const checkSelectAllStatus = this.selectAllCheck()
    const assetDetails =
      (searchAssets[currentModalAsset] || {}).asset_details || {}
    const {
      storage_url: fileUrl = '',
      metadata_info: metadataInfo = {},
      asset_id: assetId = '',
    } = assetDetails
    const { file_name: fileName = '', file_size_mb: fileSizeMb = 0 } =
      metadataInfo
    const assetMetadata = {
      fileName,
      fileUrl: '',
      assetId,
      assetDetails,
      fileSizeMb,
    }
    const { params = {} } = router
    const { assetRevId = '' } = params
    return (
      <div className={classes.searchContainer}>
        <div
          className={classes.root}
          onClick={() => {
            this.closeDropdowns()
          }}
        >
          {/* <Toolbar /> */}
          <HeaderTitle title="Search" icon="Search" />
          <Helmet>
            <title>{headerTitle}</title>
          </Helmet>
          <div style={{ width: '98%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.searchBar}>
                {/* <div>{<MfeTestUI />}</div> */}
                <SearchForm
                  fetchData={this.fetchData}
                  history={history}
                  router={router}
                  saveFile={this.saveFile}
                  fetchTypeaheadSuggestions={debouncedFetchTypeaheadSuggestions}
                  saveAppliedFilters={saveAppliedFilters}
                  currentTemplateId={templateId}
                  totalResults={totalResults}
                  doesSearchExist={this.doesSearchExist}
                  setInitFilters={this.setInitFilters}
                  placeholder={SEARCH_BOX_PLACEHOLDER}
                  fullWidth={true}
                  resetAll={this.resetAllOnStartOver}
                  showModal={showModal}
                  setSearchParamObject={this.setSearchParamObject}
                  selectedFilters={createSelctedFilters(
                    appliedFiltersForDisplay
                  )}
                  onFilterChange={this.onFilterChange}
                  onClearAppliedFilters={this.onClearAppliedFilters}
                  searchTerm={searchTerm}
                />
              </Grid>
              <Grid
                item
                container
                spacing={4}
                direction={'row'}
                style={
                  searchImagePreview ? { top: '134px', marginTop: '-14px' } : {}
                }
                className={classes.filterBar}
              >
                <Grid item xs={12} className={classes.controls}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems={'center'}
                    justifyContent="flex-start"
                  >
                    {totalResults > 0 && !controlsOpen && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.toggleControls}
                        >
                          <Tune className={classes.showControlsIcon} />
                          Show Filters
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!searchImagePreview && (
                  <Grid item className={classes.dateRangePicker}>
                    <Button
                      onClick={this.showFileTypes}
                      className={classes.dropdownFilter}
                    >
                      {appliedFiltersForDisplay['File Type']
                        ? appliedFiltersForDisplay['File Type'].length > 1
                          ? `${appliedFiltersForDisplay['File Type'][0]} + ${
                              appliedFiltersForDisplay['File Type'].length - 1
                            }`
                          : appliedFiltersForDisplay['File Type'][0]
                        : `File Type`}
                      <ExpandMoreIcon />
                    </Button>
                    {showFileTypes && (
                      <Grid
                        container
                        direction={'column'}
                        className={classes.fileTypeContiainer}
                      >
                        <Grid item>
                          <List
                            disablePadding
                            className={classes.leftList}
                            onClick={(event) => {
                              event.stopPropagation()
                            }}
                          >
                            {fileTypes.sort().map((type, index) => (
                              <div>
                                {index % 2 ? (
                                  <ListItem
                                    key={type}
                                    onClick={() => {
                                      this.updateFilter('File Type', type)
                                    }}
                                    button
                                    className={classes.fileTypeItem}
                                  >
                                    <Checkbox
                                      checked={
                                        appliedFiltersForDisplay['File Type'] &&
                                        appliedFiltersForDisplay[
                                          'File Type'
                                        ].indexOf(type) != -1
                                      }
                                      size={'small'}
                                    />
                                    <div className={classes.listText}>
                                      {type}
                                    </div>
                                  </ListItem>
                                ) : (
                                  <div />
                                )}
                              </div>
                            ))}
                          </List>
                        </Grid>
                        <Grid item>
                          <List
                            disablePadding
                            className={classes.rightList}
                            onClick={(event) => {
                              event.stopPropagation()
                            }}
                          >
                            {fileTypes.sort().map((type, index) => (
                              <div>
                                {!(index % 2) && (
                                  <ListItem
                                    key={type}
                                    onClick={() => {
                                      this.updateFilter('File Type', type)
                                    }}
                                    button
                                    className={classes.fileTypeItem}
                                  >
                                    <Checkbox
                                      checked={
                                        appliedFiltersForDisplay['File Type'] &&
                                        appliedFiltersForDisplay[
                                          'File Type'
                                        ].indexOf(type) != -1
                                      }
                                      size={'small'}
                                    />
                                    <div className={classes.listText}>
                                      {type}
                                    </div>
                                  </ListItem>
                                )}
                              </div>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    )}
                    <Button
                      onClick={this.showDates}
                      className={classes.dropdownFilter}
                    >
                      {endDate === null
                        ? `Upload Date`
                        : Moment.utc(startDate).format('MM-DD-YYYY') +
                          ' to ' +
                          Moment.utc(endDate).format('MM-DD-YYYY')}{' '}
                      <ExpandMoreIcon />
                    </Button>
                    {showDates && (
                      <div
                        className={classes.dateRange}
                        onClick={(event) => {
                          event.stopPropagation()
                        }}
                      >
                        <DateRangePicker
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) => {
                            // PropTypes.func.isRequired,
                            this.onCalenderDaysButtonClick(startDate, endDate)
                          }}
                          focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={(focus) => this.setState({ focus })} // PropTypes.func.isRequired,
                          renderCalendarInfo={this.renderDatePresets} // For Today, Yesterday, Last 7 days, Last 30 days
                          isOutsideRange={(day) =>
                            day.isAfter(moment()) ||
                            day.isBefore(moment().subtract(10, 'years'))
                          } // For enabling previous months days to select
                          hideKeyboardShortcutsPanel={true}
                          showClearDates={true}
                          hir
                        />
                      </div>
                    )}
                    {Object.keys(appliedFiltersForDisplay).length > 0 && (
                      <Button
                        color="primary"
                        data-cy="clearFiltersButton"
                        onClick={this.onClearAppliedFilters}
                      >
                        CLEAR ALL
                      </Button>
                    )}
                    {ENABLE_INDEX_SEARCH && (
                      <Grid className={classes.indexSearch}>
                        <FormControl variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Index
                          </InputLabel>
                          <Select
                            variant="standard"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.index}
                            label="Index"
                            onChange={this.handleChange}
                          >
                            <MenuItem value={'test_target'}>
                              Test Target
                            </MenuItem>
                            <MenuItem value={'test_google'}>
                              Test Google
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                )}
                {totalResults > 0 && !isFetching && (
                  <Grid
                    container
                    xs={12}
                    className={classes.middleBar}
                    direction={'row'}
                  >
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                      className={classes.middleBarSection}
                    >
                      {!searchImagePreview && (
                        <Grid item className={classes.showFilterGrid}>
                          {!filtersVisible ? (
                            <button
                              className={classes.showFilterButton}
                              onClick={this.filterClick}
                            >
                              <div className={classes.showFilterText}>
                                SHOW FILTERS{' '}
                                <MenuOpenIcon
                                  style={{ transform: 'scaleX(-1)' }}
                                />
                              </div>
                            </button>
                          ) : (
                            <button
                              className={classes.showFilterButton}
                              onClick={this.filterClick}
                            >
                              <div className={classes.showFilterText}>
                                HIDE FILTERS{' '}
                                <MenuOpenIcon style={{ paddingLeft: '10px' }} />
                              </div>
                            </button>
                          )}
                        </Grid>
                      )}
                      <Grid item className={classes.resultsBox}>
                        {totalResults > 0 && (
                          <div className={classes.resultsCount}>
                            {formatNumberToString(totalResults)} Results
                          </div>
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container direction={'row'}>
                          <Checkbox
                            checked={checkSelectAllStatus}
                            size={'small'}
                            onChange={this.onSelectAll}
                            label="Secondary"
                          />
                          <div
                            className={classes.checkboxText}
                            onClick={this.onSelectAll}
                          >
                            Select All
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                      className={classes.middleBarSection}
                    >
                      {!searchImagePreview && (
                        <Grid item>
                          <FormControl variant="outlined">
                            <Select
                              variant="outlined"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={sortBy}
                              onChange={(event) => {
                                this.onSortChange(event)
                              }}
                              SelectProps={{ 'data-cy': 'sortBy' }}
                              className={classes.selectFilter}
                            >
                              {createSortByMenuItems(SORT_OPTIONS)}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {!searchImagePreview && (
                        <Grid item>
                          <PageSizeSelector
                            className="page-size-selector"
                            pageSize={currentPageSize}
                            onPageSizeChange={this.onPageSizeChange}
                          />
                        </Grid>
                      )}
                      {!searchImagePreview && (
                        <Grid item>
                          <PageSelector
                            className="page-selector"
                            currentPage={currentPage}
                            numberOfPages={this.getNumberOfPages()}
                            prevPage={this.prevPage}
                            nextPage={this.nextPage}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item className={classes.containerWrapper} xs={12}>
                <Grid container spacing={3} direction={'row'}>
                  {doesSearchExist && filtersVisible && !searchImagePreview && (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      lg={2}
                      className={classes.facetList}
                    >
                      {isAdminOrLibrarian && (
                        <LibrarianPanel
                          templateId={templateId}
                          searchTemplates={searchTemplates}
                          onSelectionChange={this.onTemplateChange}
                        />
                      )}
                      <div style={{ marginTop: '0px' }}>
                        <FacetList
                          facets={facets}
                          searchTerm={searchTerm}
                          pageSize={currentPageSize}
                          resetPageNumber={this.resetPageNumber}
                          resetAll={this.resetAll}
                          appliedFilters={appliedFilters}
                          toggleControls={this.toggleControls}
                          controlsOpen={controlsOpen}
                          searchTemplates={searchTemplates}
                          onSearchTemplateChange={this.onSearchTemplateChange}
                          saveAppliedFilters={saveAppliedFilters}
                          initFilters={initFilters}
                          currentTemplateId={templateId}
                          ref={(instance) => {
                            this.child = instance
                          }}
                          pageActionType={pageActionType}
                          pageActionOptions={pageActionOptions}
                          isRemove={isRemove}
                          setIsRemove={() => this.setState({ isRemove: false })}
                          setInitFilters={this.setInitFilters}
                          history={history}
                          router={router}
                          userId={userId}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={filtersVisible && !searchImagePreview ? 9 : 12}
                    lg={filtersVisible && !searchImagePreview ? 10 : 12}
                    className={classes.assetContainer}
                    style={
                      filtersVisible
                        ? { marginLeft: '0' }
                        : { marginLeft: '0px' }
                    }
                  >
                    <Grid container spacing={2} direction={'row'}>
                      <Grid item xs={12}>
                        {isFetchingWithResults ||
                        (!isFetchingWithResults &&
                          totalResults > 0 &&
                          isFetching) ? (
                          <Grid
                            container
                            spacing={4}
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <CircularProgress className={classes.progress} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            spacing={4}
                            direction={'row'}
                            justifyContent={'left'}
                            alignItems={'left'}
                          >
                            <Grid item xs={12}>
                              <AssetList
                                searchAssets={
                                  searchImagePreview
                                    ? searchAssetList
                                    : searchAssets
                                }
                                onSelection={this.onSelection}
                                selectedItems={selectedItems}
                                searchImagePreview={searchImagePreview}
                                clearImageSearch={() => this.clearImageSearch}
                                onClickDownloadAsset={this.onClickDownloadAsset}
                                openModal={this.onAssetSelect}
                                checkAll={checkAll}
                                controlsOpen={controlsOpen}
                                isToggleRightSidenav={isToggleRightSidenav}
                                rightSideNavComponent={rightSideNavComponent}
                                asset={
                                  isRightOrLeftArrowClicked
                                    ? assetMetadata
                                    : metaDataSingleAsset
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                        {!isRefresh &&
                          !isFetching &&
                          !totalResults &&
                          doesSearchExist && (
                            <Grid
                              container
                              spacing={2}
                              direction={'row'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h1 className={classes.noResult}>
                                  No Results Found
                                </h1>
                              </Grid>
                            </Grid>
                          )}
                        {!isFetchingWithResults &&
                          totalResults > 0 &&
                          !isFetching &&
                          !searchImagePreview && (
                            <Grid
                              container
                              spacing={4}
                              direction={'row'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <Grid item>
                                <PageSelector
                                  className="page-selector"
                                  currentPage={currentPage}
                                  numberOfPages={this.getNumberOfPages()}
                                  prevPage={this.prevPage}
                                  nextPage={this.nextPage}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                      {searchImagePreview &&
                        searchAssetList.length < searchAssets.length && (
                          <Grid
                            container
                            justifyContent="center"
                            spacing={1}
                            direction="row"
                            style={{ margin: '10px', paddingBottom: '30px' }}
                          >
                            <Button
                              aria-label="Delete"
                              onClick={() => this.loadMoreAssets()}
                              data-cy="loadMore"
                            >
                              <ExpandMoreIcon />
                              Load More
                            </Button>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.root}>
          {isModal && (
            <MetadataPreview
              asset={
                isRightOrLeftArrowClicked ? assetMetadata : metaDataSingleAsset
              }
              selectedAssetId={assetId ? assetId : assetRevId}
              isFetching={isFetching}
              showPreview={showModal}
              closePreview={(isArrowShow = true) =>
                this.closeMetaDataPreview(isArrowShow)
              }
              downloadAsset={this.onClickDownloadAsset}
              nextAsset={this.nextAsset}
              prevAsset={this.prevAsset}
              setSaveAlertAction={setSaveAlertAction}
              consumer="search"
              isMetadataFormEdited={isMetadataFormEdited}
              history={history}
              router={router}
              isArrowShow={assetDetails && isEmpty(assetDetails) ? false : true}
              originalAssetId={originalAssetId}
              handleOriginalAssetId={this.handleOriginalAssetId}
              isSearchComponent={true}
            />
          )}
          {selectedAssetCount > 0 && (
            <BulkActionBar
              closeBulkActionBar={this.closeBulkActionBar}
              selectedAssets={downloadAssets}
              noOfSelectedItems={selectedAssetCount}
              onDownloadClicked={this.onDownloadClicked}
              toggleRightSidenav={toggleRightSidenav}
              isToggleRightSidenav={isToggleRightSidenav}
              rightSideNavComponent={rightSideNavComponent}
              isDownloadInitiated={isDownloadInitiated}
              downloadAssetType={downloadAssetType}
              isAdminOrLibrarian={isAdminOrLibrarian}
              onDeleteAssets={this.toggleDeleteAssetsConfirmation}
              enableDeleteAsset={isAdminOrLibrarian}
              onDownloadMetadata={this.downloadMetadata}
              isMetadataDownloadInitiated={isMetadataDownloadInitiated}
              onClearSelection={this.onClearSelection}
            />
          )}
          {displayDeleteAssetConfirmationModal && (
            <DeleteAssetsDailog
              noOfSelectedAssets={selectedAssetCount}
              deleteAssets={this.onDeleteAssets}
              toggleConfirmationModal={this.toggleDeleteAssetsConfirmation}
              dialogOpen={displayDeleteAssetConfirmationModal}
            />
          )}
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  // appliedFilters changed to PropTypes.object because checking dynamic keys is complex
  appliedFilters: PropTypes.object,
  classes: PropTypes.object.isRequired,
  deleteRevisions: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  deleteAssetInProgress: PropTypes.bool,
  downloadAssetList: PropTypes.object,
  downloadInProgress: PropTypes.bool,
  email: PropTypes.string,
  isAdminOrLibrarian: PropTypes.bool,
  facets: PropTypes.object.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  fetchReverseImageAssets: PropTypes.func.isRequired,
  fetchSearchTemplates: PropTypes.func,
  fetchTypeaheadSuggestions: PropTypes.func,
  headerTitle: PropTypes.string.isRequired,
  isStrict: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingWithResults: PropTypes.bool,
  isToggleRightSidenav: PropTypes.bool,
  rightSideNavComponent: PropTypes.string,
  saveAppliedFilters: PropTypes.func,
  searchAssets: PropTypes.arrayOf(
    PropTypes.shape({
      asset_data: PropTypes.object,
      asset_id: PropTypes.string,
      asset_storage_info: PropTypes.object,
      content_id: PropTypes.string,
      original_document_id: PropTypes.string,
      subject_code: PropTypes.string,
    })
  ).isRequired,
  searchTemplates: PropTypes.object,
  setDeleteAssetInProgress: PropTypes.func,
  setDownloadAssetInProgress: PropTypes.func,
  setSortFields: PropTypes.func,
  sortFields: PropTypes.object,
  totalResults: PropTypes.number.isRequired,
  updateDownloadAssets: PropTypes.func,
  updateFacetFilter: PropTypes.func,
  templateId: PropTypes.string,
  toggleRightSidenav: PropTypes.func,
  setSaveAlertAction: PropTypes.func,
  isMetadataFormEdited: PropTypes.bool,
  userId: PropTypes.string,
  resetProjectData: PropTypes.func,
  fetchMetadatadetails: PropTypes.func,
  isMetadataDownloadInitiated: PropTypes.bool,
  clearAssetRevisions: PropTypes.func,
  clearSearchAssets: PropTypes.func.isRequired,
  fetchAssetMetadata: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = (state) => ({
  appliedFilters: selectAppliedFilters()(state),
  email: selectUserEmail()(state),
  isAdminOrLibrarian:
    selectIsAdGroupSuperAdmin()(state) || selectIsLibrarian()(state),
  facets: selectAssetSearchResultFacets()(state) || {},
  headerTitle: selectHeaderTitle()(state) || '',
  isFetching: selectIsFetchAssetsInProgress()(state),
  isToggleRightSidenav: selectIsToggleRightSidenav()(state),
  rightSideNavComponent: selectRightSideNavComponent()(state),
  isFetchingWithResults: makeSelectIsFetchAssetsInProgress()(state),
  isStrict: selectIsReturnStrict()(state),
  searchAssets: selectAssetSearchResults()(state) || [],
  searchTemplates: selectSearchTemplates()(state),
  totalResults: selectTotalSearchResults()(state) || 0,
  sortFields: selectSortFields()(state),
  downloadAssetList: selectDownloadAssets()(state),
  downloadInProgress: selectDownloadAssetInProgress()(state),
  deleteAssetInProgress: selectDeleteAssetInProgress()(state),
  templateId: selectTemplateId()(state),
  isMetadataFormEdited: selectIsMetadataFormEdited()(state),
  userId: selectUserId()(state),
  searchImagePreview: selectPreviewUrl()(state),
  isMetadataDownloadInitiated: selectIsMetadataDownloadInProgress()(state),
  searchTerm: selectSearchTerm()(state),
  pageSize: selectPageSize()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteRevisions,
      fetchAssets,
      fetchReverseImageAssets,
      fetchSearchTemplates,
      fetchTypeaheadSuggestions,
      resetProjectData,
      saveAppliedFilters,
      setDeleteAssetInProgress,
      setDownloadAssetInProgress,
      setSortFields,
      updateDownloadAssets,
      updateFacetFilter,
      toggleRightSidenav,
      setSaveAlertAction,
      fetchMetadatadetails,
      dispatch,
      clearAssetRevisions,
      fetchAssetMetadata,
      updateSearchTerm,
      setPreviewImage,
      clearSearchAssets,
      setCurrentPageSize,
      setMetadataLoading,
      getAssetClassificationHierarchy,
      setTemplateId,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <Search {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(withRouter(MyComponent)))
